import React, { useState } from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import { isNonEmptyObject } from "../../utils"

const TippedCell = (props) => {
  const [overflowArray, setoverflowArray] = useState([])

  const checkCellOverflow = (e, index) => {
    let _overflowArray = [...overflowArray]
    if (e.target.offsetWidth < e.target.scrollWidth) _overflowArray[index] = true
    else _overflowArray[index] = false
    setoverflowArray(_overflowArray)
  }

  return (
    <div
      onMouseEnter={(e) => checkCellOverflow(e, props.index)}
      style={{
        ...(props.style || {}),
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      className="action"
    >
      {isNonEmptyObject(props.errorObject) && (
        <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}>{props.errorObject.tooltip}</Tooltip>}>
          <span
            style={{
              color: "red",
              fontWeight: "bold",
              marginRight: "6px",
              fontSize: "16pt",
            }}
          >
            !
          </span>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        overlay={
          overflowArray[props.index] ? <Tooltip style={{ position: "absolute" }}>{props.content}</Tooltip> : <></>
        }
      >
        <span style={{ ...(isNonEmptyObject(props.errorObject) ? { borderBottom: "1px solid red" } : {}) }}>
          {props.content}
        </span>
      </OverlayTrigger>
    </div>
  )
}

export default TippedCell
