import React, { useEffect } from "react"
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap"
import { useConfig } from "../../contexts/configContext"

const Dashboard = (props) => {
  const config = useConfig()
  const namespace = config.namespace

  useEffect(() => {
    props.setBreadcrumb("", "Dashboard", "")
  }, [])

  return (
    <div className="page">
      <Tabs defaultActiveKey="Application Alarm" id="sepp-tabs-dashboard" className="mb-3" justify>
        <Tab eventKey="Application Alarm" title="APPLICATION ALARM">
          <iframe
            style={{ marginTop: "20px" }}
            src={`/grafana/d/e2c25567-d073-46fb-8ac5-e0e56fe36777/sepp?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="Platform Alarm" title="PLATFORM ALARM">
          <iframe
            style={{ marginTop: "20px" }}
            src={`/grafana/d/e2c25567-d073-46fb-8ac5-e0e56fe36777/sepp?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="Application Performance" title="APPLICATION PERFORMANCE">
          <iframe
            style={{ marginTop: "20px" }}
            src={`/grafana/d/e2c25567-d073-46fb-8ac5-e0e56fe36777/sepp?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="Platform Performance" title="PLATFORM PERFORMANCE">
          <iframe
            style={{ marginTop: "20px" }}
            src={`/grafana/d/e2c25567-d073-46fb-8ac5-e0e56fe36777/sepp?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="SEPP Capacity" title="SEPP CAPACITY">
          <iframe
            style={{ marginTop: "20px" }}
            src={`/grafana/d/e2c25567-d073-46fb-8ac5-e0e56fe36777/sepp?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
      </Tabs>
    </div>
  )
}

export default Dashboard
