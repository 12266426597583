import React, { useEffect, useState } from "react"
import { Button, Tab, Tabs } from "react-bootstrap"
import { useConfig } from "../../contexts/configContext"
import ListTable from "../SharedComponents/ListTable"
import useHttpApi from "../useHttpApi"
import { isNonEmptyObject, isNonEmptyString } from "../../utils"

function KPI(props) {
  const [cvasCounters, setcvasCounters] = useState(null)
  const [pvasCounters, setpvasCounters] = useState(null)
  const [domainTable, setdomainTable] = useState(null)
  const [tdr, settdr] = useState(null)
  const [tdrCounters, settdrCounters] = useState(null)
  const [routingTable, setroutingTable] = useState(null)
  const [domainsInRoutes, setdomainsInRoutes] = useState(null)
  const [cvasTable, setcvasTable] = useState([])
  const [pvasTable, setpvasTable] = useState([])

  const config = useConfig()
  const httpApi = useHttpApi()

  const inboundHeader = [
    {
      title: "Domain",
      field: "domain",
      size: "20%",
    },
    {
      title: "Filtered",
      field: "filtered",
      size: "20%",
    },
    {
      title: "Incoming",
      field: "incoming",
      size: "20%",
    },
    {
      title: "Manipulated",
      field: "manipulation",
      size: "20%",
    },
    {
      title: "Res Manipulated",
      field: "res_manipulation",
      size: "20%",
    },
  ]

  const outboundHeader = [
    {
      title: "Domain",
      field: "domain",
      size: "20%",
    },
    {
      title: "Filtered",
      field: "filtered",
      size: "20%",
    },
    {
      title: "Incoming",
      field: "incoming",
      size: "20%",
    },
    {
      title: "Manipulated",
      field: "manipulation",
      size: "20%",
    },
    {
      title: "Res Manipulated",
      field: "res_manipulation",
      size: "20%",
    },
  ]

  useEffect(() => {
    props.setBreadcrumb("", "KPI", "")
  }, [])

  const buildVasTable = (original) => {
    // Step 1: Estrazione dei domini univoci
    const domains = {}
    for (const key in original) {
      const parts = key.split("|")[1]
      if (!domains[parts]) {
        domains[parts] = {
          domain: parts,
          filtered: 0,
          incoming: 0,
          manipulation: 0,
          res_manipulation: 0,
        }
      }
    }

    // Step 2: Calcolo dei valori appropriati per ogni dominio
    for (const key in original) {
      const parts = key.split("|")[1]
      const prefix = key.split("|")[0]
      if (domains[parts]) {
        domains[parts][prefix] = original[key]
      }
    }

    // Step 3: Costruzione dell'array di oggetti
    const processed = Object.values(domains).filter((el) => isNonEmptyString(el.domain))
    return processed
  }

  // const resultExample = [
  //   {
  //     domain: "mnc001.mcc001",
  //     filtered: 17,
  //     incoming: 14,
  //     manipulation: 14,
  //     res_manipulation: 12,
  //   },
  //   {
  //     domain: "mnc001.mcc002",
  //     filtered: 12,
  //     incoming: 221,
  //     manipulation: 100,
  //     res_manipulation: 4,
  //   },
  //   {
  //     domain: "mnc002.mcc003",
  //     filtered: 10,
  //     incoming: 123,
  //     manipulation: 96,
  //     res_manipulation: 4,
  //   },
  // ]

  useEffect(() => {
    fetchCounters().then((res) => {
      setcvasTable(buildVasTable(res.cvas))
      setpvasTable(buildVasTable(res.pvas))
      setcvasCounters(res.cvas)
      setpvasCounters(res.pvas)
    })

    fetchDomainTable().then((res) => {
      setdomainTable(res)
    })

    fetchTDR().then((res) => {
      settdr(res)
    })

    fetchRoutingTable().then((res) => setroutingTable(res))

    setInterval(
      () =>
        fetchCounters().then((res) => {
          setcvasTable(buildVasTable(res.cvas))
          setpvasTable(buildVasTable(res.pvas))
          setcvasCounters(res.cvas)
          setpvasCounters(res.pvas)
        }),
      5000
    )
  }, [])

  useEffect(() => {
    domainTable && routingTable && findDomainsInRoutingTable()
  }, [domainTable, routingTable])

  const fetchCounters = async () => {
    let cvas = await httpApi.callGetAPI({ url: `h_json/cvas_counters` })
    let pvas = await httpApi.callGetAPI({ url: `h_json/pvas_counters` })
    return { cvas, pvas }
  }

  const fetchDomainTable = async () => {
    return await httpApi.callGetAPI({ url: `json/domaintable` })
  }

  const fetchRoutingTable = async () => {
    return await httpApi.callGetAPI({ url: `json/routing` })
  }

  const fetchTDR = async () => {
    return await httpApi.callGetAPI({ url: `list_json/tdr_repo` })
  }

  const resetCounters = async () => {
    await httpApi.callDeleteAPI({ url: `h_reset/cvas_counters` })
    await httpApi.callDeleteAPI({ url: `h_reset/pvas_counters` })

    fetchCounters().then((res) => {
      setcvasTable(buildVasTable(res.cvas))
      setpvasTable(buildVasTable(res.pvas))
      setcvasCounters(res.cvas)
      setpvasCounters(res.pvas)
    })
  }

  const downloadCounters = () => {
    let vasObject = { pvas: pvasCounters, cvas: cvasCounters }
    console.log(vasObject)

    const blob = new Blob([JSON.stringify(vasObject, null, 2)], { type: "application/json" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = "vasCounters.json"
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const findDomainsInRoutingTable = () => {
    let _domainInRoutes = []
    for (let route of routingTable) {
      let found = false
      for (let domain of domainTable) {
        if (route.domain_name === domain.domain_name) {
          _domainInRoutes = [..._domainInRoutes, domain]
          found = true
          break
        }
      }
      if (found) continue
    }
    setdomainsInRoutes(_domainInRoutes)
  }

  useEffect(() => {
    if (domainsInRoutes && tdr) {
      let _tdrCounters = domainsInRoutes.map((d) => {
        return { dns: d.dns, counter: 0 }
      })
      tdr.forEach((_tdr) => {
        _tdrCounters.forEach((tdrc) => {
          if (_tdr.apiroot.includes(tdrc.dns)) {
            tdrc.counter++
          }
        })
      })
      _tdrCounters = _tdrCounters.filter((t) => t.counter > 0)
      settdrCounters(_tdrCounters)
    }
  }, [domainsInRoutes, tdr])

  return (
    <>
      {/* <Spinner show={config.loading} /> */}
      {config.loading ? null : (
        <>
          <div className="page">
            <Tabs defaultActiveKey="Inbound" id="sepp-tabs-kpi" className="mb-3" justify>
              <Tab eventKey="Inbound" title="INBOUND COUNTERS">
                <h5 className="title mt-5">Inbound Global Counters</h5>
                <div className="rowDiv j-evenly">
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Total:</span>
                      <span>{cvasCounters?.incoming || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Filtered:</span>
                      <span>{cvasCounters?.filtered || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Manipulated:</span>
                      <span>{cvasCounters?.manipulation || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Sent:</span>
                      <span>{cvasCounters?.handshake_req_sent || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Handshake Successful:</span>
                      <span>{cvasCounters?.handshake_req_success || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Failed:</span>
                      <span>{cvasCounters?.handshake_req_failed || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Not Answered:</span>
                      <span>{cvasCounters?.handshake_req_timeout || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                </div>
                {cvasTable.length > 0 ? (
                  <ListTable
                    dense
                    elements={cvasTable}
                    header={inboundHeader}
                    sortable
                    searchable
                    collapsible
                  ></ListTable>
                ) : (
                  <h3 className="no-table-data">There are no particular cases to show</h3>
                )}
              </Tab>
              <Tab eventKey="Outbound" title="OUTBOUND COUNTERS">
                <h5 className="title mt-5">Outbound Global Counters</h5>
                <div className="rowDiv j-evenly">
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Total:</span>
                      <span>{pvasCounters?.incoming || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Filtered:</span>
                      <span>{pvasCounters?.filtered || 0}</span>
                    </div>
                    <div className="separator"></div>

                    <div className="data-row">
                      <span>Manipulated:</span>
                      <span>{pvasCounters?.manipulation || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Handshake Received:</span>
                      <span>{pvasCounters?.handshake_req_sent || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Successful:</span>
                      <span>{pvasCounters?.handshake_req_success || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Failed:</span>
                      <span>{pvasCounters?.handshake_req_failed || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                </div>
                {pvasTable.length > 0 ? (
                  <ListTable
                    dense
                    elements={pvasTable}
                    header={outboundHeader}
                    sortable
                    searchable
                    collapsible
                  ></ListTable>
                ) : (
                  <h3 className="no-table-data">There are no particular cases to show</h3>
                )}
              </Tab>
            </Tabs>
            <div style={{ float: "right", marginRight: "1.5%" }}>
              <Button onClick={() => downloadCounters()}>DOWNLOAD</Button>
              <Button onClick={() => resetCounters()} style={{ marginLeft: "20px" }}>
                RESET ALL
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default KPI
